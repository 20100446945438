import React, { useEffect } from 'react'
import { Box, Checkbox, Field, Heading, Input, Message, Text } from 'ui'
import { LocationSelector } from 'components/LocationSelector'
import { track } from 'lib/amplitude'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { DetailsForm, detailsSchema } from '../../schemas'
import { PositionSelector } from 'components/PositionSelector'
import { useCompanyLocation } from 'hooks/useCompanyLocation'
import { Map } from 'components/Map'
import { useShiftEditor } from '../../useShiftEditor'
import { Footer } from '../../Footer'
import { AcknowledgeTryoutList } from './AcknowledgeTryoutList'
import { AddLocation } from 'components/AddLocation'
import { AddPosition } from 'components/AddPosition'
import { useLocationCoordinates } from 'hooks/useLocationCoordinates'
import { useIsDrivingPosition } from './useIsDrivingPosition'
import { TryoutLabel } from 'components/tryouts/TryoutLabel'
import { AddPositionTrigger } from 'pages/ShiftEditorPage/pages/DetailsPage/AddPositionTrigger'
import { AddLocationTrigger } from 'pages/ShiftEditorPage/pages/DetailsPage/AddLocationTrigger'
import { ShiftType } from 'pages/ShiftEditorPage/types'
import { isProp22MinimumPayEnabled } from 'lib/flags'

export function DetailsPage() {
  useEffect(() => {
    track('impression, ShiftEditor_Details_page')
  }, [])

  const { shiftData, isTryout, shiftType, setShiftData, goNext } =
    useShiftEditor()
  const methods = useForm<DetailsForm>({
    resolver: zodResolver(detailsSchema),
    defaultValues: shiftData?.details,
  })
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    register,
    setError,
  } = methods

  const isLocationLess = watch('locationless')
  const selectedLocationId = watch('locationId')
  const selectedLocation = useCompanyLocation(selectedLocationId)
  const coordinates = useLocationCoordinates(selectedLocation)
  const selectedPositionId = watch('positionId')
  const isDrivingPosition = useIsDrivingPosition(selectedPositionId)

  const onSubmit = handleSubmit((data) => {
    if (
      isProp22MinimumPayEnabled() &&
      isDrivingPosition &&
      selectedLocation?.address?.state === 'CA' &&
      (!data.expectedMileage || data.expectedMileage === 0)
    ) {
      setError('expectedMileage', {
        type: 'custom',
        message: 'Mileage is required for driving positions',
      })
      return
    }

    setShiftData({
      details: data,
    })
    goNext()
  })

  const title = isTryout
    ? 'Tryout'
    : shiftType === ShiftType.LongTermAssignment
    ? 'Assignment'
    : 'Shift'
  return (
    <Box>
      <Heading level={[2, 1]} my={4}>
        Tell Us About Your {title}
      </Heading>
      <Box width={[1, 1, '65rem']}>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            {isTryout ? (
              <Box mb={3}>
                <TryoutLabel />
              </Box>
            ) : null}
            {shiftType === ShiftType.LongTermAssignment ? (
              <Field
                label={
                  <Heading level={3} my={1}>
                    Assignment Name
                  </Heading>
                }
                error={errors.assignmentName?.message?.toString()}
              >
                <Input
                  placeholder="Night Schedule"
                  {...register('assignmentName')}
                />
              </Field>
            ) : null}
            <Field
              label={
                <Heading level={3} my={1}>
                  {isTryout ? '1. Select Tryout Position' : 'Select Position'}
                </Heading>
              }
              cta={
                <AddPosition
                  trigger={<AddPositionTrigger />}
                  onPositionAdded={(positionId) => {
                    setValue('positionId', positionId)
                  }}
                />
              }
              error={errors.positionId?.message?.toString()}
            >
              <Controller
                name={'positionId'}
                control={control}
                render={({ field }) => (
                  <PositionSelector
                    value={field.value as number}
                    placeholder={'Select a position'}
                    onChange={(newValue) => {
                      field.onChange(
                        Number((newValue as { value: string }).value)
                      )
                    }}
                  />
                )}
              />
            </Field>
            {isProp22MinimumPayEnabled() &&
            isDrivingPosition &&
            selectedLocation?.address?.state === 'CA' ? (
              <Field
                label={'Estimated Driving Distance'}
                error={errors.expectedMileage?.message?.toString()}
              >
                <Text fontSize={1} color={'lightText'} mb={1}>
                  Let us know how far a worker should expect to drive. This will
                  help us recommend competitive wages. Under local California
                  regulations, driving shifts should be limited to no more than
                  12 drops and stay within a 50-mile radius of the starting
                  point.
                  <br />
                  <br />
                  If a shift's actual mileage exceeds the estimated mileage,
                  this may result in a surcharge to you, and additional payment
                  to the worker, to comply with regulatory requirements.
                </Text>
                <Input
                  placeholder={'e.g. 20'}
                  {...register('expectedMileage')}
                />
              </Field>
            ) : null}
            {isTryout ? (
              <>
                <Box mt={4}>
                  <Heading level={3} my={1}>
                    2. Acknowledge List
                  </Heading>
                  <AcknowledgeTryoutList />
                </Box>
                {errors.acknowledgeTryoutList?.message ? (
                  <Message variant={'error'} mt={3}>
                    {errors.acknowledgeTryoutList?.message.toString()}
                  </Message>
                ) : null}
              </>
            ) : null}
            <Field
              mt={4}
              label={
                <Heading level={3} my={1}>
                  {isTryout ? '3. Select Tryout Location' : 'Select Location'}
                </Heading>
              }
              cta={
                <AddLocation
                  trigger={<AddLocationTrigger />}
                  onLocationSaved={(locationId) => {
                    setValue('locationId', locationId)
                  }}
                />
              }
              error={errors.locationId?.message?.toString()}
            >
              <Controller
                name={'locationId'}
                control={control}
                render={({ field }) => (
                  <LocationSelector
                    value={field.value as number}
                    placeholder={'Select a location to report to'}
                    onChange={(newValue) => {
                      field.onChange(
                        Number((newValue as { value: string }).value)
                      )
                    }}
                  />
                )}
              />
            </Field>
            <Controller
              name={'locationless'}
              control={control}
              render={({ field }) => (
                <Checkbox {...field}>
                  Worker does not need to report to this location
                </Checkbox>
              )}
            />
            {isLocationLess ? (
              <Message variant={'info'} mt={3}>
                You must enter a location even if your workers do not need to
                report to a location. We use the location you enter to determine
                the timezone of your shift.
              </Message>
            ) : null}
            {coordinates ? (
              <Box mt={3}>
                <Map
                  center={coordinates}
                  zoom={11}
                  markers={[
                    {
                      position: coordinates,
                      title: selectedLocation?.name ?? 'Selected Location',
                    },
                  ]}
                />
              </Box>
            ) : null}
            <Footer />
          </form>
        </FormProvider>
      </Box>
    </Box>
  )
}
