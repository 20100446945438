import React, { useState, useEffect } from 'react'
import { Modal } from '@workwhile/ui'
import { ReviewStep } from './steps/ReviewStep'
import { ResultsStep } from './steps/ResultsStep'
import { useAssignmentDetailContextStateValue } from 'pages/AssignmentDetailPage/context'
import { useCancelShifts } from 'queries/assignment/useCancelShifts'
import { useAssignmentShifts } from 'queries/assignment/useAssignmentShifts'

enum CancelShiftFlowStep {
  REVIEW = 'REVIEW',
  RESULTS = 'RESULTS',
}

interface CancelShiftFlowProps {
  isOpen: boolean
  close: () => void
  selectedShifts: string[]
}

interface CancelledShift {
  id: string
  startsAt: string
  endsAt: string
  location: {
    address: {
      city: string
      state: string
      timezone: string
    }
  }
}

export const CancelShiftFlow = ({
  isOpen,
  close,
  selectedShifts,
}: CancelShiftFlowProps) => {
  const { assignment, company } = useAssignmentDetailContextStateValue()
  const [currentStep, setCurrentStep] = useState(CancelShiftFlowStep.REVIEW)
  const [cancelledShifts, setCancelledShifts] = useState<CancelledShift[]>([])

  const { data } = useAssignmentShifts(assignment.id)

  const cancelShifts = useCancelShifts({
    companyId: company?.id ?? 0,
    assignmentId: assignment.id,
  })

  // Cleanup effect when modal closes
  useEffect(() => {
    if (!isOpen) {
      setCurrentStep(CancelShiftFlowStep.REVIEW)
    }
  }, [isOpen])

  const handleConfirmCancel = async () => {
    if (!data?.shifts) return

    // Store the shift data before cancellation
    const shiftsToCancel = data.shifts
      .filter((shift) => selectedShifts.includes(shift.id.toString()))
      .map((shift) => ({
        id: shift.id.toString(),
        startsAt: shift.startsAt,
        endsAt: shift.endsAt,
        location: {
          address: {
            city: shift.location.address.city,
            state: shift.location.address.state,
            timezone: shift.location.address.timezone,
          },
        },
      }))

    try {
      await cancelShifts.mutateAsync({
        shift_ids: selectedShifts.map(Number),
      })
      setCancelledShifts(shiftsToCancel)
      setCurrentStep(CancelShiftFlowStep.RESULTS)
    } catch (error) {
      // Error handling will be managed by the mutation
    }
  }

  const renderStep = () => {
    switch (currentStep) {
      case CancelShiftFlowStep.REVIEW:
        return (
          <ReviewStep
            selectedShifts={selectedShifts}
            onConfirm={handleConfirmCancel}
            onCancel={close}
            isLoading={cancelShifts.isPending}
            error={cancelShifts.error}
          />
        )
      case CancelShiftFlowStep.RESULTS:
        return (
          <ResultsStep
            cancelledShifts={cancelledShifts}
            success={!cancelShifts.isError}
            error={cancelShifts.error}
            onClose={close}
          />
        )
    }
  }

  return (
    <Modal open={isOpen} onClose={close} width={800}>
      {renderStep()}
    </Modal>
  )
}
