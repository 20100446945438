import React, { useMemo } from 'react'
import {
  Box,
  Button,
  Field,
  Flex,
  Heading,
  Message,
  Select,
  Text,
  Table,
} from '@workwhile/ui'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { TimeSelector } from 'components/TimeSelector'
import { formatInTimeZone } from 'date-fns-tz'
import {
  getW2BreakOptions,
  UNPAID_BREAK_OPTIONS,
  getLongDuration,
  getTimezoneName,
} from 'lib/time'
import { useAssignmentShifts } from 'queries/assignment/useAssignmentShifts'
import { FiClock } from 'react-icons/fi'
import { useW2MinimumLunchLength } from 'queries/company/useW2MinimumLunchLength'
import { useLunchLabel } from '../../../ShiftEditorPage/useLunchLabel'
import { z } from 'zod'
import { useShiftTime } from '../../../ShiftEditorPage/useShiftTime'
import { parse } from 'date-fns'

// Define form schema
const editTimesSchema = z
  .object({
    startTime: z.object(
      {
        value: z.string(),
      },
      {
        required_error: 'Please select a start time',
      }
    ),
    endTime: z.object(
      {
        value: z.string(),
      },
      {
        required_error: 'Please select an end time',
      }
    ),
    lunchLength: z.object({
      value: z.number(),
    }),
  })
  .superRefine((data, context) => {
    // Validate start and end times aren't equal
    if (data.startTime?.value === data.endTime?.value) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Shift must be longer than 0 minutes.',
        path: ['endTime'],
      })
      return false
    }

    return true
  })

type EditTimesForm = z.infer<typeof editTimesSchema>

interface EditTimesStepProps {
  assignmentId: number
  selectedShifts: string[]
  isW2: boolean
  onUpdateEditData: (data: {
    startTime: string
    endTime: string
    lunchLength: number
  }) => void
  onNext: () => void
  editData?: {
    startTime: string
    endTime: string
    lunchLength: number
  }
}

export const EditTimesStep = ({
  assignmentId,
  selectedShifts,
  isW2,
  onUpdateEditData,
  onNext,
  editData,
}: EditTimesStepProps) => {
  const { data: shiftsData } = useAssignmentShifts(assignmentId)
  const selectedShiftData = shiftsData?.shifts.filter((shift) =>
    selectedShifts.includes(shift.id.toString())
  )

  const firstShift = selectedShiftData?.[0]
  const timezone =
    firstShift?.location?.address?.timezone ?? 'timezone unavailable'

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<EditTimesForm>({
    resolver: zodResolver(editTimesSchema),
    defaultValues: {
      startTime: editData ? { value: editData.startTime } : { value: '' },
      endTime: editData ? { value: editData.endTime } : { value: '' },
      lunchLength: editData ? { value: editData.lunchLength } : { value: 0 },
    },
    mode: 'onSubmit',
  })

  const startTime = watch('startTime')
  const endTime = watch('endTime')
  const lunchLength = watch('lunchLength')

  // Get minimum lunch length requirement
  const { data: minimumLunchLength = 0 } = useW2MinimumLunchLength({
    locationId: firstShift?.locationId,
    startTime: startTime?.value
      ? new Date(`1970-01-01T${startTime.value}:00Z`)
      : undefined,
    endTime: endTime?.value
      ? new Date(`1970-01-01T${endTime.value}:00Z`)
      : undefined,
  })

  const breakOptions = useMemo(
    () => (isW2 ? getW2BreakOptions(minimumLunchLength) : UNPAID_BREAK_OPTIONS),
    [isW2, minimumLunchLength]
  )

  const lunchLabel = useLunchLabel({
    isW2,
    minimumLunchLength,
  })

  // Calculate hours and changes
  const { originalTotalHours, newTotalHours, totalHoursChange } =
    useMemo(() => {
      if (!selectedShiftData?.length || !startTime?.value || !endTime?.value) {
        return { originalTotalHours: 0, newTotalHours: 0, totalHoursChange: 0 }
      }

      const originalHours = selectedShiftData.reduce((total, shift) => {
        const shiftStart = new Date(shift.startsAt)
        const shiftEnd = new Date(shift.endsAt)
        return (
          total + (shiftEnd.getTime() - shiftStart.getTime()) / (1000 * 60 * 60)
        )
      }, 0)

      // Create dates for time comparison
      const newStart = new Date(`1970-01-01T${startTime.value}`)
      let newEnd = new Date(`1970-01-01T${endTime.value}`)

      // If end time is before start time, assume it's the next day
      if (newEnd < newStart) {
        newEnd = new Date(`1970-01-02T${endTime.value}`)
      }

      const newHoursPerShift =
        (newEnd.getTime() - newStart.getTime()) / (1000 * 60 * 60)
      const totalNewHours = newHoursPerShift * selectedShiftData.length

      return {
        originalTotalHours: originalHours,
        newTotalHours: totalNewHours,
        totalHoursChange: totalNewHours - originalHours,
      }
    }, [selectedShiftData, startTime, endTime])

  const { isOvernight, spansDaylightSaving, totalDurationInMinutes } =
    useShiftTime({
      date: firstShift?.startsAt ? new Date(firstShift.startsAt) : new Date(),
      startTime: startTime?.value,
      endTime: endTime?.value,
      lunchLength: lunchLength?.value,
      timezone,
    })

  const onSubmit = handleSubmit((data) => {
    if (
      !data.startTime?.value ||
      !data.endTime?.value ||
      data.lunchLength?.value === undefined ||
      data.lunchLength?.value === null
    ) {
      return
    }

    onUpdateEditData({
      startTime: data.startTime.value,
      endTime: data.endTime.value,
      lunchLength: data.lunchLength.value,
    })
    onNext()
  })

  const handleSubmitClick = () => {
    onSubmit()
  }

  return (
    <Box>
      <Heading level={2} mb={4}>
        Edit Shift Times
      </Heading>

      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <Flex flexDirection={['column', 'row']}>
          <Field
            width={[1, 1 / 2]}
            mr={[0, 2]}
            label="Start time"
            error={errors.startTime?.message}
          >
            <Controller
              name="startTime"
              control={control}
              render={({ field }) => (
                <TimeSelector
                  aria-label="Select Start Time"
                  placeholder="Select a start time"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Field>

          <Field
            width={[1, 1 / 2]}
            ml={[0, 2]}
            label="End time"
            error={errors.endTime?.message}
          >
            <Controller
              name="endTime"
              control={control}
              render={({ field }) => (
                <TimeSelector
                  aria-label="Select End Time"
                  placeholder="Select an end time"
                  value={field.value}
                  from={startTime?.value}
                  showDuration={true}
                  onChange={field.onChange}
                />
              )}
            />
          </Field>
        </Flex>

        <Message hideIcon variant="fixed" systemLevel={false} mb={3}>
          <Flex alignItems="center">
            <FiClock />
            <Box ml={2}>
              Time zone for these shifts is {getTimezoneName(timezone)}
            </Box>
          </Flex>
        </Message>

        {isOvernight && totalDurationInMinutes ? (
          <Message variant="warning" systemLevel={false} mb={3} width="100%">
            This shift is currently scheduled for{' '}
            <strong>{getLongDuration(totalDurationInMinutes)}</strong>{' '}
            (excluding unpaid breaks). If this is incorrect, please correct the
            Start or End time.
          </Message>
        ) : null}

        {spansDaylightSaving ? (
          <Message variant="warning" systemLevel={false} mt={3} width="100%">
            This shift spans a daylight saving time change. Please double check
            the Start and End times.
          </Message>
        ) : null}

        <Field
          label={
            <Flex alignItems="center">
              <Text fontWeight="bold" mr={2} style={{ flex: 1 }}>
                {lunchLabel}
              </Text>
              {isW2 ? (
                <Button
                  variant="text"
                  size="small"
                  as="a"
                  href="https://www.dol.gov/agencies/whd/state/meal-breaks"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  More info
                </Button>
              ) : null}
            </Flex>
          }
          error={errors.lunchLength?.message}
          mb={4}
        >
          <Controller
            name="lunchLength"
            control={control}
            render={({ field }) => (
              <Select
                aria-label="Select Break Time"
                placeholder="Select a break time"
                value={field.value}
                options={breakOptions}
                onChange={field.onChange}
              />
            )}
          />
        </Field>

        <Box mb={4}>
          <Text mb={3}>{selectedShifts.length} shift(s) selected</Text>

          <Flex bg="gray.100" p={3} mb={3}>
            <Box flex={1}>
              <Text>Original total hours</Text>
              <Text>{originalTotalHours.toFixed(1)} hours</Text>
            </Box>
            <Box flex={1}>
              <Text>New total hours</Text>
              <Text>{newTotalHours.toFixed(1)} hours</Text>
            </Box>
            <Box flex={1}>
              <Text textAlign="right">Total change</Text>
              <Text
                textAlign="right"
                color={totalHoursChange < 0 ? 'red.500' : 'green.500'}
              >
                {totalHoursChange > 0 ? '+' : ''}
                {totalHoursChange.toFixed(1)} hours
              </Text>
            </Box>
          </Flex>

          <Table
            columns={[
              {
                header: 'Date',
                accessorKey: 'date',
              },
              {
                header: 'Original Time',
                accessorKey: 'originalTime',
              },
              {
                header: 'New Time',
                accessorKey: 'newTime',
              },
              {
                header: 'Change',
                accessorKey: 'change',
                cell: () => (
                  <Text
                    textAlign="right"
                    color={totalHoursChange < 0 ? 'red.500' : 'green.500'}
                  >
                    {(
                      totalHoursChange / (selectedShiftData?.length || 1)
                    ).toFixed(1)}{' '}
                    hours
                  </Text>
                ),
              },
            ]}
            data={
              selectedShiftData?.map((shift) => ({
                date: formatInTimeZone(
                  new Date(shift.startsAt),
                  timezone,
                  'EEE, MMM d, yyyy'
                ),
                originalTime: `${formatInTimeZone(
                  new Date(shift.startsAt),
                  timezone,
                  'h:mm a'
                )} - ${formatInTimeZone(
                  new Date(shift.endsAt),
                  timezone,
                  'h:mm a (zzz)'
                )}`,
                newTime:
                  startTime?.value && endTime?.value
                    ? `${formatInTimeZone(
                        parse(startTime.value, 'HH:mm', new Date()),
                        timezone,
                        'h:mm a'
                      )} - ${formatInTimeZone(
                        parse(endTime.value, 'HH:mm', new Date()),
                        timezone,
                        'h:mm a (zzz)'
                      )}`
                    : '-',
              })) || []
            }
          />
        </Box>

        <Flex justifyContent="flex-end">
          <Button type="button" onClick={handleSubmitClick}>
            Review Changes
          </Button>
        </Flex>
      </form>
    </Box>
  )
}
