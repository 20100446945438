import React from 'react'
import { Button, DropdownMenu, Flex } from '@workwhile/ui'
import { UserPlus, Pencil } from 'lucide-react'
import { useAssignmentWorkflowsContextStateValue } from '../Calendar/context/AssignmentWorkflowsProvider'
import { useGate } from 'statsig-react'
import { FeatureGates } from 'lib/statsig/feature_gates'

interface ListViewControlsProps {
  selectedShifts: string[]
  selectedTab: string
}

export const ListViewControls = ({
  selectedShifts,
  selectedTab,
}: ListViewControlsProps) => {
  const { editShiftFlow } = useAssignmentWorkflowsContextStateValue()
  const { value: shouldEnableLTActions } = useGate(
    FeatureGates.EnableLTAActions
  )

  if (!shouldEnableLTActions) {
    return null
  }

  return (
    <Flex alignItems="center" justifyContent="flex-end" zIndex={1}>
      <DropdownMenu
        trigger={
          <Button variant="primary" disabled={selectedTab !== 'upcoming'}>
            Actions
          </Button>
        }
      >
        <DropdownMenu.Item
          onClick={(e) => {
            if (selectedShifts.length === 0 || selectedTab !== 'upcoming') {
              e.preventDefault()
              return
            }
            // TODO: Implement this
            // requestWorkersFlow.open(selectedShifts)
          }}
          disabled={selectedShifts.length === 0 || selectedTab !== 'upcoming'}
        >
          <Flex as="span" alignItems="center" style={{ gap: 8 }}>
            <UserPlus size={14} />
            Request Workers
          </Flex>
        </DropdownMenu.Item>
        <DropdownMenu.Item
          onClick={(e) => {
            if (selectedShifts.length === 0 || selectedTab !== 'upcoming') {
              e.preventDefault()
              return
            }
            editShiftFlow.open(selectedShifts)
          }}
          disabled={selectedShifts.length === 0 || selectedTab !== 'upcoming'}
        >
          <Flex as="span" alignItems="center" style={{ gap: 8 }}>
            <Pencil size={14} />
            Edit Shift Times
          </Flex>
        </DropdownMenu.Item>
      </DropdownMenu>
    </Flex>
  )
}
