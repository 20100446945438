import React, { PropsWithChildren } from 'react'
import { Page } from 'pages/layout'
import { useAssignmentDetailContextStateValue } from './context'

export const AssignmentDetailPageContent: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { assignment } = useAssignmentDetailContextStateValue()

  return <Page title={assignment.name}>{children}</Page>
}
