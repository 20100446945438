import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'
import { useQueryClient } from '@tanstack/react-query'

export type AssignmentWorkflowsContextState = {
  requestWorkersFlow: {
    open: () => void
    close: () => void
    isOpen: boolean
  }
  editShiftFlow: {
    open: (selectedShifts?: string[]) => void
    close: () => void
    isOpen: boolean
    selectedShifts?: string[]
  }
}

const AssignmentWorkflowsContext =
  createContext<AssignmentWorkflowsContextState | null>(null)

export const useAssignmentWorkflowsContextStateValue = () => {
  const context = useContext(AssignmentWorkflowsContext)
  if (!context) {
    throw new Error(
      'useAssignmentWorkflowsContextStateValue must be used within a AssignmentWorkflowsProvider'
    )
  }
  return context
}

type AssignmentWorkflowsProviderProps = PropsWithChildren

/**
 * Provides the state for the assignment workflows such as the request workers, etc.
 */
export const AssignmentWorkflowsProvider = ({
  children,
}: AssignmentWorkflowsProviderProps) => {
  const [isRequestWorkersOpen, setIsRequestWorkersOpen] = useState(false)
  const [isEditShiftOpen, setIsEditShiftOpen] = useState(false)
  const [selectedShifts, setSelectedShifts] = useState<string[]>([])
  const queryClient = useQueryClient()

  const value: AssignmentWorkflowsContextState = {
    requestWorkersFlow: {
      open: () => setIsRequestWorkersOpen(true),
      close: () => setIsRequestWorkersOpen(false),
      isOpen: isRequestWorkersOpen,
    },
    editShiftFlow: {
      open: (shifts?: string[]) => {
        if (shifts) {
          setSelectedShifts(shifts)
        }
        setIsEditShiftOpen(true)
      },
      close: () => {
        queryClient.refetchQueries({ type: 'active' })
        setIsEditShiftOpen(false)
        setSelectedShifts([])
      },
      isOpen: isEditShiftOpen,
      selectedShifts,
    },
  }

  return (
    <AssignmentWorkflowsContext.Provider value={value}>
      {children}
    </AssignmentWorkflowsContext.Provider>
  )
}
