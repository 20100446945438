import React, { useState } from 'react'
import { Text, Modal, Box, Textarea, toast, Message } from 'ui'
import { BlockModalProps } from './types'
import { useUpdateWorkerStatusForCompanyMutation } from 'queries/worker'
import { StatusForCompanyEnum } from 'api/worker'

const MIN_REASON_CHARACTERS = 25

export function BlockWithReason(
  props: BlockModalProps & {
    onSuccess: (status?: StatusForCompanyEnum) => void
  }
) {
  const { setModalType, worker, onSuccess } = props
  const [reason, setReason] = useState<string>('')
  const [error, setError] = useState<Error>()
  const { mutate: updateStatus, isPending } =
    useUpdateWorkerStatusForCompanyMutation({
      workerId: worker.id,
      onSuccess(status) {
        onSuccess(status)
        toast.success(`You've successfully blocked ${worker.name}`)
        setModalType(null)
      },
      onError(err) {
        setError(err)
      },
    })

  return (
    <Modal
      title={'Why do you want to block this worker?'}
      loading={isPending}
      open={true}
      onClose={() => setModalType(null)}
      okText={'Block Worker'}
      onOk={() => {
        if (!reason.trim()) {
          setError(new Error('Please provide a reason'))
          return
        }

        if (reason.trim().length < MIN_REASON_CHARACTERS) {
          setError(
            new Error(
              `Please provide a reason with at least ${MIN_REASON_CHARACTERS} characters`
            )
          )
          return
        }

        updateStatus({
          action: 'block',
          reason,
        })
      }}
    >
      <Text>Blocked workers:</Text>
      <ul>
        <li>
          - will be removed from all shifts they are scheduled for at your
          company
        </li>
        <li>- will be unable to accept shifts posted by your company</li>
      </ul>
      <Box mt={3}>
        <Textarea
          minRows={2}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder={
            'Please provide a detailed reason for blocking (e.g., missed shift, misconduct)'
          }
        />
        <Text
          color={'lightText'}
          fontSize={'12px'}
          lineHeight={'18px'}
          mt={1}
          textAlign={'right'}
        >
          {reason.trim().length} characters (minimum {MIN_REASON_CHARACTERS}{' '}
          characters)
        </Text>
      </Box>
      {error ? (
        <Message mt={3} variant={'error'}>
          {error.message}
        </Message>
      ) : null}
    </Modal>
  )
}
