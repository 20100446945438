import React, { forwardRef } from 'react'
import {
  Button,
  Card,
  CardProps,
  Flex,
  Link,
  Text,
  Heading,
  useAppTheme,
} from '@workwhile/ui'
import * as Popover from '@radix-ui/react-popover'
import { CalendarCheck, CalendarClock, X } from 'lucide-react'

import { User } from 'typings/common_defs'
import { ShiftDetailBodySection } from '../ShiftDetailBodySection'

type WorkerShiftCellDetail = Omit<
  CardProps,
  'ref' | 'children' | 'shadowless'
> & {
  worker?: Pick<User, 'id' | 'name' | 'workForAssignment'>
}

export const WorkerCardCellDetail = forwardRef<
  HTMLDivElement,
  WorkerShiftCellDetail
>(({ worker, ...rest }, ref) => {
  const { space } = useAppTheme()

  const getCalloutsCountByStatus = (
    work: User['workForAssignment'] | undefined,
    status: 'approved' | 'requested'
  ) => {
    return (
      work?.filter((work) =>
        work.callouts?.some((callout) => callout.status === status)
      ).length ?? 0
    )
  }

  return (
    <Card ref={ref} width={[200, 320]} {...rest}>
      <Popover.Close
        asChild
        style={{ position: 'absolute', top: space[1], right: space[1] }}
      >
        <Button
          variant="text"
          p={1}
          style={{
            height: 'auto',
            padding: space[1],
          }}
        >
          <X size={18} />
        </Button>
      </Popover.Close>
      <Heading level={4} fontWeight="400" my={0}>
        {worker?.name}
      </Heading>
      <ShiftDetailBodySection Icon={CalendarCheck} mt={2}>
        <Text color="lightText">
          Approved Callouts:{' '}
          {getCalloutsCountByStatus(worker?.workForAssignment, 'approved')}
        </Text>
      </ShiftDetailBodySection>
      <ShiftDetailBodySection Icon={CalendarClock} mt={1}>
        <Text color="lightText">
          Pending Callouts:{' '}
          {getCalloutsCountByStatus(worker?.workForAssignment, 'requested')}
        </Text>
      </ShiftDetailBodySection>
      <Flex mt={3} justifyContent="flex-end">
        <Button as={Link} to={`/workers/${worker?.id}`} kind="medium">
          <Text color="white">View Worker</Text>
        </Button>
      </Flex>
    </Card>
  )
})

WorkerCardCellDetail.displayName = 'WorkerCardCellDetail'
