import { useMutation, useQueryClient } from '@tanstack/react-query'
import { cancelShifts } from 'api/assignment/cancelShifts'
import { assignmentShiftsKey } from './keys'

interface UseCancelShiftsParams {
  companyId: number
  assignmentId: number
}

export const useCancelShifts = ({
  companyId,
  assignmentId,
}: UseCancelShiftsParams) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: Parameters<typeof cancelShifts>[2]) =>
      cancelShifts(companyId, assignmentId, payload),
    onSuccess: async () => {
      // Invalidate and wait for the new data to be fetched
      await queryClient.invalidateQueries({
        queryKey: assignmentShiftsKey(assignmentId),
        refetchType: 'active', // Only refetch active queries
      })
    },
  })
}
