import React from 'react'
import { Box, Button, Flex, Table, Text, Message, Heading } from '@workwhile/ui'
import { useAssignmentShifts } from 'queries/assignment/useAssignmentShifts'
import { formatInTimeZone } from 'date-fns-tz'
import { getLocalTimezone, getTimezoneName } from 'lib/time'
import { FiClock } from 'react-icons/fi'
import { useAssignmentDetailContextStateValue } from 'pages/AssignmentDetailPage/context'
import { getTimezoneFromLocation } from 'lib/time/getTimezoneFromLocation'

interface ReviewStepProps {
  selectedShifts: string[]
  editData?: {
    startTime: string
    endTime: string
    lunchLength: number | null
  }
  onBack: () => void
  onConfirm: () => void
  isLoading?: boolean
  error?: Error | null
}

export const ReviewStep = ({
  selectedShifts,
  editData,
  onBack,
  onConfirm,
  isLoading,
  error,
}: ReviewStepProps) => {
  const { assignment, company } = useAssignmentDetailContextStateValue()
  const { data } = useAssignmentShifts(assignment.id)

  const firstShift = data?.shifts.find((shift) =>
    selectedShifts.includes(shift.id.toString())
  )
  const timezone =
    getTimezoneFromLocation(firstShift?.location) || getLocalTimezone()

  // Guard against missing company id
  if (!company?.id) {
    return null
  }

  const columns = [
    { header: 'Date', accessorKey: 'date' },
    { header: 'Current Time', accessorKey: 'currentTime' },
    { header: 'New Time', accessorKey: 'newTime' },
    { header: 'Change', accessorKey: 'change' },
  ]

  const selectedShiftData =
    data?.shifts.filter((shift) =>
      selectedShifts.includes(shift.id.toString())
    ) || []

  // Instead, use this simpler function that just converts 24h to 12h format
  const formatTime = (timeString: string) => {
    const [hours, minutes] = timeString.split(':')
    const hour = parseInt(hours)
    const hour12 = hour % 12 || 12
    const ampm = hour >= 12 ? 'PM' : 'AM'
    return `${hour12}:${minutes} ${ampm}`
  }

  // Calculate hours change for each shift
  const getHoursChange = (
    startOld: string,
    endOld: string,
    startNew: string,
    endNew: string
  ) => {
    const oldStart = new Date(
      `1970-01-01T${formatInTimeZone(new Date(startOld), timezone, 'HH:mm')}:00`
    )
    const oldEnd = new Date(
      `1970-01-01T${formatInTimeZone(new Date(endOld), timezone, 'HH:mm')}:00`
    )
    const newStart = new Date(`1970-01-01T${startNew}:00`)
    const newEnd = new Date(`1970-01-01T${endNew}:00`)

    const oldHours = (oldEnd.getTime() - oldStart.getTime()) / (1000 * 60 * 60)
    const newHours = (newEnd.getTime() - newStart.getTime()) / (1000 * 60 * 60)

    return (newHours - oldHours).toFixed(1)
  }

  return (
    <Box>
      <Heading level={2} mb={4}>
        Review Changes
      </Heading>

      {error ? (
        <Message variant="error" mb={4}>
          Failed to update shifts: {error.message}
        </Message>
      ) : null}

      <Box mb={1}>
        <Text fontWeight="bold" mb={2}>
          New Times
        </Text>
        <Text>
          Start: {editData?.startTime ? formatTime(editData.startTime) : ''}{' '}
          {formatInTimeZone(new Date(), timezone, 'zzz')}
        </Text>
        <Text>
          End: {editData?.endTime ? formatTime(editData.endTime) : ''}{' '}
          {formatInTimeZone(new Date(), timezone, 'zzz')}
        </Text>
        {editData?.lunchLength ? (
          <Text>Break: {editData.lunchLength} minutes</Text>
        ) : null}
      </Box>

      <Message hideIcon variant="fixed" systemLevel={false} mb={4}>
        <Flex alignItems="center">
          <FiClock />
          <Box ml={2}>
            Time zone for these shifts is {getTimezoneName(timezone)}
          </Box>
        </Flex>
      </Message>

      <Box mb={4}>
        <Text fontWeight="bold" mb={2}>
          Affected Shifts ({selectedShiftData.length})
        </Text>
        <Table
          columns={columns}
          data={selectedShiftData.map((shift) => {
            const hoursChange = getHoursChange(
              shift.startsAt,
              shift.endsAt,
              editData?.startTime || '',
              editData?.endTime || ''
            )

            return {
              date: formatInTimeZone(
                new Date(shift.startsAt),
                timezone,
                'EEE, MMM dd'
              ),
              currentTime: `${formatInTimeZone(
                new Date(shift.startsAt),
                timezone,
                'h:mm a'
              )} - ${formatInTimeZone(
                new Date(shift.endsAt),
                timezone,
                'h:mm a (zzz)'
              )}`,
              newTime: `${formatTime(editData?.startTime || '')} - ${formatTime(
                editData?.endTime || ''
              )} (${formatInTimeZone(
                new Date(shift.startsAt),
                timezone,
                'zzz'
              )})`,
              change:
                Number(hoursChange) < 0
                  ? `${hoursChange} hours`
                  : `+${hoursChange} hours`,
            }
          })}
        />
      </Box>

      <Flex justifyContent="flex-end" gap={2} mt={4}>
        <Button variant="secondary" onClick={onBack}>
          Back
        </Button>
        <Button
          onClick={onConfirm}
          disabled={!editData || isLoading}
          loading={isLoading}
        >
          Confirm Changes
        </Button>
      </Flex>
    </Box>
  )
}
