import React from 'react'
import { Avatar, Box, Button, Flex, InputMask, MaskEnum, Message } from 'ui'
import styled from 'styled-components'
import { SupervisorRequestType } from '../../schemas'
import { ExternalSupervisor } from './ExternalSupervisor'
import { useSupervisor } from './useSupervisor'
import { Controller } from 'react-hook-form'

interface Props {
  id: number
}

const CardContainer = styled(Flex).attrs({
  width: 1,
  p: 3,
  my: 3,
})`
  ${({ theme: { colors, radii } }) => `
    background-color: ${colors.neutrals[50]};
    border-radius: ${radii.standard};
  `}
`

export function SupervisorCard(props: Props) {
  const { data, dataPath, errors, remove, control } = useSupervisor(props.id)

  if (data.requestType === SupervisorRequestType.FreeForm) {
    return (
      <CardContainer>
        <ExternalSupervisor id={props.id} />
      </CardContainer>
    )
  }

  return (
    <CardContainer
      flexDirection={'row'}
      alignItems={['flex-start', 'center']}
      flexWrap={'wrap'}
    >
      <Avatar
        size={'medium'}
        user={{
          userId: data.id.toString(),
          name: data.name,
        }}
      />
      <Flex
        flex={1}
        flexDirection={['column', 'row']}
        alignItems={['flex-start', 'center']}
      >
        <Box ml={2} flex={1} style={{ textTransform: 'capitalize' }}>
          {data.name}
        </Box>
        <Box ml={2} flex={1}>
          <Controller
            control={control}
            name={`${dataPath}.phoneNumber`}
            render={({ field }) => (
              <InputMask
                variant={'small'}
                mask={MaskEnum.PhoneNumberAlternate}
                {...field}
              />
            )}
          />
        </Box>
        <Button
          kind={'medium'}
          variant={'text'}
          onClick={remove}
          mt={[2, 0]}
          ml={[2, 0]}
        >
          Remove
        </Button>
      </Flex>
      {errors.phoneNumber ? (
        <Message width={1} mt={3} variant={'error'}>
          {errors.phoneNumber.message.toString()}
        </Message>
      ) : null}
    </CardContainer>
  )
}
