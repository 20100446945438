import React from 'react'
import * as Popover from '@radix-ui/react-popover'
import { CalendarViewContextState } from '../../context'
import { BaseWorkerCardCell } from './BaseWorkerCardCell'
import { Text } from '@workwhile/ui'
import { WorkerCardCellDetail } from './WorkerCardCellDetail'

type WorkerCardProps = {
  worker?: CalendarViewContextState['state']['workers'][number]
}

export const WorkerCardCell = ({ worker }: WorkerCardProps) => {
  if (!worker) {
    return (
      <BaseWorkerCardCell
        title={
          <Text fontSize={0} color="lightText" textAlign="left">
            Finding worker ...
          </Text>
        }
      />
    )
  }
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <BaseWorkerCardCell
          avatar={{
            photoUrl: worker.profilePicUrl,
            name: worker.name,
          }}
          title={
            <Text fontSize={0} textAlign="left">
              {worker.name}
            </Text>
          }
        />
      </Popover.Trigger>
      <Popover.Content
        side="right"
        align="start"
        sideOffset={5}
        asChild
        style={{ zIndex: 1 }}
      >
        <WorkerCardCellDetail worker={worker} />
      </Popover.Content>
    </Popover.Root>
  )
}
