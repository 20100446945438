import React from 'react'
import { Box, Button, Text, Heading, Table } from '@workwhile/ui'
import { CheckCircle, XCircle } from 'lucide-react'
import { formatInTimeZone } from 'date-fns-tz'

interface ResultsStepProps {
  cancelledShifts: Array<{
    id: string
    startsAt: string
    endsAt: string
    location: {
      address: {
        city: string
        state: string
        timezone: string
      }
    }
  }>
  success: boolean
  error?: Error | null
  onClose: () => void
}

export const ResultsStep = ({
  cancelledShifts,
  success,
  error,
  onClose,
}: ResultsStepProps) => {
  const timezone = cancelledShifts[0]?.location?.address?.timezone

  const columns = [
    { header: 'Date', accessorKey: 'date' },
    { header: 'Time', accessorKey: 'time' },
  ]

  return (
    <Box>
      <Heading level={2} mb={4}>
        Confirmation
      </Heading>

      {success ? (
        <>
          <Box mt={4} mb={3} display="flex" alignItems="center">
            <CheckCircle color="#0AB7A3" size={24} />
            <Text ml={3} fontSize="16px" color="primary">
              Successfully cancelled {cancelledShifts.length} shifts
            </Text>
          </Box>

          <Table
            columns={columns}
            data={cancelledShifts.map((shift) => ({
              date: formatInTimeZone(
                new Date(shift.startsAt),
                timezone,
                'EEE, MMM d, yyyy'
              ),
              time: `${formatInTimeZone(
                new Date(shift.startsAt),
                timezone,
                'h:mm a'
              )} - ${formatInTimeZone(
                new Date(shift.endsAt),
                timezone,
                'h:mm a (zzz)'
              )}`,
            }))}
          />
        </>
      ) : (
        <Box mb={4} display="flex" alignItems="center">
          <XCircle color="#DC2626" size={24} />
          <Text ml={3} fontSize="16px">
            Failed to cancel shifts: {error?.message}
          </Text>
        </Box>
      )}

      <Box display="flex" justifyContent="flex-end" mt={4}>
        <Button onClick={onClose}>Close</Button>
      </Box>
    </Box>
  )
}
