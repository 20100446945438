import { useQuery } from '@tanstack/react-query'
import { getAssignmentShifts } from 'api/assignment/getAssignmentShifts'
import { assignmentShiftsKey } from './keys'

export function useAssignmentShifts(assignmentId: number) {
  return useQuery({
    queryKey: assignmentShiftsKey(assignmentId),
    queryFn: () => getAssignmentShifts(assignmentId),
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 10 * 60 * 1000, // 10 minutes
  })
}
