import React from 'react'
import { CalendarHeader } from './CalendarBody/CalendarHeader'
import { CalendarBody } from './CalendarBody/CalendarBody'
import { Box } from '@workwhile/ui'
import { RequestWorkersFlow } from './RequestWorkersFlow/RequestWorkersFlow'
import { EditShiftFlow } from '../../EditShiftFlow'

export const CalendarView = () => {
  return (
    <Box>
      <CalendarHeader />
      <CalendarBody />
      <RequestWorkersFlow />
      <EditShiftFlow />
    </Box>
  )
}
