import React from 'react'
import { track } from 'lib/amplitude'
import {
  Box,
  Field,
  Heading,
  Input,
  InputMask,
  Button,
  Message,
  Link,
  Paragraph,
  Flex,
  MaskEnum,
} from 'ui'
import * as zod from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSignUpMutation } from 'queries/user'
import { useParams, useSearchParams } from 'react-router-dom'

const schema = zod
  .object({
    name: zod
      .string({
        required_error: 'Name is required',
      })
      .min(1, 'Name is required'),
    phoneNumber: zod
      .string({
        required_error: 'Phone number is required',
      })
      .min(10, 'Phone number must contain 10 digits'),
    password: zod
      .string({
        required_error: 'Password is required',
      })
      .min(8, 'At least 8 characters required'),
    passwordConfirm: zod
      .string({
        required_error: 'Confirming password is required',
      })
      .min(8, 'At least 8 characters required'),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: "Passwords don't match",
    path: ['passwordConfirm'], // path of error
  })

type FormData = zod.infer<typeof schema>

export function OnboardingForm() {
  const [searchParams] = useSearchParams()
  const params = useParams<{ token: string }>()
  const companyName = searchParams.get('company')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const {
    mutate: signUp,
    isPending: isSigningUp,
    error,
  } = useSignUpMutation({
    onSuccess() {
      location.href = '/home'
    },
  })

  const onSubmit = handleSubmit((data) => {
    track('button_click, sign_up_email')
    signUp({
      ...data,
      email: searchParams.get('email') ?? '',
      inviteToken: params.token ?? '',
    })
    track('event, document_acknowledged', {
      document: 'Customer Agreement',
      email: searchParams.get('email') ?? '',
    })
  })

  return (
    <Box margin={'auto'} pb={4} width={[1, 450]}>
      <form onSubmit={onSubmit} autoComplete={'off'}>
        <Heading level={2}>Set Up Your Account</Heading>
        <Paragraph>
          You've been invited to join your team's WorkWhile account. Please
          setup your login credentials below.
        </Paragraph>
        {companyName ? (
          <Heading level={4} my={0} mt={4} mb={3}>
            {companyName}
          </Heading>
        ) : null}
        <Field label={'Full Name'} error={errors.name?.message?.toString()}>
          <Input
            disabled={isSigningUp}
            autoFocus={true}
            placeholder={'e.g. Jane Doe'}
            {...register('name')}
          />
        </Field>
        <Field
          label={'Phone Number'}
          error={errors.phoneNumber?.message?.toString()}
        >
          <Controller
            control={control}
            name={'phoneNumber'}
            render={({ field }) => (
              <InputMask
                autoComplete={'off'}
                disabled={isSigningUp}
                placeholder={'e.g. 555-555-5555'}
                mask={MaskEnum.PhoneNumberAlternate}
                {...field}
              />
            )}
          />
        </Field>
        <Flex flexDirection={'row'} flexWrap={'wrap'}>
          <Box width={[1, 1 / 2]} pr={[0, 2]}>
            <Field
              label={'Password'}
              error={errors.password?.message?.toString()}
            >
              <Input
                disabled={isSigningUp}
                autoComplete={'new-password'}
                type={'password'}
                placeholder={'Enter a Password'}
                {...register('password')}
              />
            </Field>
          </Box>
          <Box width={[1, 1 / 2]} pl={[0, 2]}>
            <Field
              label={'Confirm Password'}
              error={errors.passwordConfirm?.message?.toString()}
            >
              <Input
                disabled={isSigningUp}
                autoComplete={'new-password'}
                type={'password'}
                placeholder={'Re-type your Password'}
                {...register('passwordConfirm')}
              />
            </Field>
          </Box>
        </Flex>
        <Box mt={4}>
          <Button
            block={true}
            size={'large'}
            type={'submit'}
            loading={isSigningUp}
          >
            Proceed
          </Button>
        </Box>
        {error ? (
          <Message width={1} mt={2} mb={4} variant={'error'}>
            {error.message}
          </Message>
        ) : null}
        <Box mt={4}>
          By clicking "Proceed", you agree to WorkWhile's&nbsp;
          <Link
            to={'https://www.workwhilejobs.com/customer_agreement'}
            target={'_blank'}
          >
            Services Agreement
          </Link>
          .
        </Box>
      </form>
    </Box>
  )
}
