import React from 'react'
import styled from 'styled-components'
import { Box, BoxProps, Button } from 'ui'

export const UpperScrollBoundary = styled(Box)
  .withConfig({
    shouldForwardProp: (prop) => !['onButtonClick'].includes(prop),
  })
  .attrs<
    BoxProps & {
      onButtonClick: () => void
    }
  >((props) => ({
    position: 'absolute',
    zIndex: 2,
    display: 'inline-flex',
    justifyContent: 'center',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    bg: 'white',
    p: 3,
    top: 0,
    left: 0,
    width: '100%',
    children: (
      <Button onClick={props.onButtonClick}>Load more previous shifts</Button>
    ),
  }))``
