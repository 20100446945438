import { WorkerStatusFilter } from 'api/assignment/getCalendarEvents'

export const assignmentKeys = {
  all: ['assignment'] as const,
  lists: () => [...assignmentKeys.all, 'list'] as const,
  list: (filters: Record<string, any>) =>
    [...assignmentKeys.lists(), { ...filters }] as const,
  details: () => [...assignmentKeys.all, 'detail'] as const,
  detail: (id: number) => [...assignmentKeys.details(), id] as const,
  shifts: (id: number) => [...assignmentKeys.detail(id), 'shifts'] as const,
} as const

// Legacy exports for backward compatibility
export const assignmentDetailKey = (assignmentId: number | string) => [
  ...assignmentKeys.all,
  assignmentId.toString(),
]

export const assignmentShiftsKey = (assignmentId: number) => [
  ...assignmentKeys.all,
  'shifts',
  assignmentId,
]

export const assignmentCalendarEventsKey = (
  assignmentId: number | string,
  date: Date,
  workStatus: WorkerStatusFilter
) => [
  ...assignmentKeys.all,
  assignmentId.toString(),
  'calendar-events',
  date.toISOString(),
  workStatus,
]
