import React, { useState, useCallback, useEffect } from 'react'
import { Modal } from '@workwhile/ui'
import { EditTimesStep } from './steps/EditTimesStep'
import { ReviewStep } from './steps/ReviewStep'
import { ResultsStep } from './steps/ResultsStep'
import { useAssignmentWorkflowsContextStateValue } from '../ScheduleContent/Calendar/context/AssignmentWorkflowsProvider'
import { useAssignmentDetailContextStateValue } from 'pages/AssignmentDetailPage/context'
import { useUpdateShiftTimes } from 'queries/assignment/useUpdateShiftTimes'
import { zonedTimeToUtc } from 'date-fns-tz'
import { useAssignmentShifts } from 'queries/assignment/useAssignmentShifts'

enum EditShiftFlowStep {
  EDIT_TIMES = 'EDIT_TIMES',
  REVIEW = 'REVIEW',
  RESULTS = 'RESULTS',
}

export const EditShiftFlow = () => {
  const { assignment, company } = useAssignmentDetailContextStateValue()
  const {
    editShiftFlow: { isOpen, close, selectedShifts: contextSelectedShifts },
  } = useAssignmentWorkflowsContextStateValue()
  const { data: shiftsData } = useAssignmentShifts(assignment.id)

  const [currentStep, setCurrentStep] = useState(
    contextSelectedShifts?.length ? EditShiftFlowStep.EDIT_TIMES : undefined
  )
  const [selectedShifts, setSelectedShifts] = useState<string[]>(
    contextSelectedShifts || []
  )
  const [editData, setEditData] = useState<{
    startTime: string
    endTime: string
    lunchLength: number | null
  }>()

  // Update local state when context shifts change
  useEffect(() => {
    if (contextSelectedShifts?.length) {
      setSelectedShifts(contextSelectedShifts)
      setCurrentStep(EditShiftFlowStep.EDIT_TIMES)
    }
  }, [contextSelectedShifts])

  // Cleanup effect when modal closes
  useEffect(() => {
    if (!isOpen) {
      // Reset all local state
      setSelectedShifts([])
      setEditData(undefined)

      // Ensure body scroll is enabled
      document.body.style.overflow = 'unset'
      document.body.style.pointerEvents = 'auto'
    }

    return () => {
      // Cleanup on unmount
      document.body.style.overflow = 'unset'
      document.body.style.pointerEvents = 'auto'
    }
  }, [isOpen])

  const updateShiftTimes = useUpdateShiftTimes({
    companyId: company?.id ?? 0,
    assignmentId: assignment.id,
  })

  const handleClose = useCallback(() => {
    // Ensure we cleanup before closing
    document.body.style.overflow = 'unset'
    document.body.style.pointerEvents = 'auto'
    close()
  }, [close])

  const handleConfirmChanges = async () => {
    if (!editData || !shiftsData?.shifts) return

    const firstShift = shiftsData.shifts.find((shift) =>
      selectedShifts.includes(shift.id.toString())
    )
    const timezone = firstShift?.location?.address?.timezone
    if (!timezone) {
      throw new Error('Timezone is required to update shift times')
    }

    try {
      // Get the date part from the first shift
      const shiftDate = new Date(firstShift?.startsAt ?? '')
        .toISOString()
        .split('T')[0]

      // Convert local times to UTC using the actual shift date
      const startsAtUtc = zonedTimeToUtc(
        `${shiftDate}T${editData.startTime}`,
        timezone
      ).toISOString()

      const endsAtUtc = zonedTimeToUtc(
        `${shiftDate}T${editData.endTime}`,
        timezone
      ).toISOString()

      await updateShiftTimes.mutateAsync({
        shift_ids: selectedShifts.map(Number),
        starts_at: startsAtUtc,
        ends_at: endsAtUtc,
        lunch_length: editData.lunchLength,
      })

      // Don't manually construct the edited shifts - let the refetched data show the updates
      setCurrentStep(EditShiftFlowStep.RESULTS)
    } catch (error) {
      // Error handling will be managed by the mutation
    }
  }

  const handleBack = useCallback(() => {
    // Reset the error state when going back
    updateShiftTimes.reset()
    setCurrentStep(EditShiftFlowStep.EDIT_TIMES)
  }, [updateShiftTimes])
  if (!isOpen) return null

  const renderStep = () => {
    switch (currentStep) {
      case EditShiftFlowStep.EDIT_TIMES:
        return (
          <EditTimesStep
            assignmentId={assignment.id}
            selectedShifts={selectedShifts}
            isW2={company.w2 ?? false}
            onUpdateEditData={setEditData}
            onNext={() => setCurrentStep(EditShiftFlowStep.REVIEW)}
          />
        )
      case EditShiftFlowStep.REVIEW:
        return (
          <ReviewStep
            selectedShifts={selectedShifts}
            editData={editData}
            onBack={handleBack}
            onConfirm={handleConfirmChanges}
            isLoading={updateShiftTimes.isPending}
            error={updateShiftTimes.error}
          />
        )
      case EditShiftFlowStep.RESULTS:
        return (
          <ResultsStep
            selectedShiftIds={selectedShifts}
            success={!updateShiftTimes.isError}
            error={updateShiftTimes.error}
            onClose={handleClose}
          />
        )
    }
  }

  return (
    <Modal open={isOpen} onClose={handleClose} width={800}>
      {renderStep()}
    </Modal>
  )
}
