import { graphql } from 'api/graphql'
import { format } from 'date-fns'
import { Assignment } from 'typings/common_defs'
import { indexBy, path } from 'ramda'
import {
  CalendarViewListing,
  CalendarViewShift,
  CalendarViewWork,
  CalendarViewWorker,
} from 'pages/AssignmentDetailPage/types'
import { getShiftDate } from 'pages/AssignmentDetailPage/utils/getShiftDate'

export type WorkerStatusFilter = 'assigned' | 'unassigned' | 'all'

type GetCalendarEventsRequest = {
  assignmentId: number
  startDate: Date
  endDate: Date
  workStatus: WorkerStatusFilter
}

type GetCalendarEventsResponse = {
  shiftsById: Record<CalendarViewShift['id'], CalendarViewShift>
  shiftsByDay: Record<
    string /**** yyyy-MM-dd */,
    Pick<CalendarViewShift, 'id'>[]
  >
  workers: CalendarViewWorker[]
  listings: CalendarViewListing[]
}

type GetCalendarEventsResponseDTO = {
  data: {
    company: {
      assignment: Assignment & {
        shifts: (CalendarViewShift & {
          work: (CalendarViewWork & {
            worker: Pick<CalendarViewWorker, 'id'>
          })[]
        })[]

        workers: CalendarViewWorker[]

        listings: {
          id: number
          numWorkersPending: number
          shifts: { id: number }[]
        }[]
      }
    }
  }
}

export const getCalendarEvents = async ({
  assignmentId,
  startDate,
  endDate,
  workStatus = 'assigned',
}: GetCalendarEventsRequest): Promise<GetCalendarEventsResponse> => {
  const startDateDay = format(startDate, 'yyyyMMdd')
  const endDateDay = format(endDate, 'yyyyMMdd')
  const workerFilters = [
    workStatus && `status: "${workStatus}"`,
    workStatus === 'assigned' && `startDate: "${startDateDay}"`,
  ]
    .filter(Boolean)
    .join(', ')

  const data = await graphql<GetCalendarEventsResponseDTO>(
    `
      company {
        assignment (assignmentId: ${assignmentId}) {
          id
          name
          workers (${workerFilters}) {
            name
            id
            profilePicUrl
            workForAssignment (assignmentId: ${assignmentId}) {
              callouts {
                status
              }
            }
          }
          shifts (startDate: "${startDateDay}", endDate: "${endDateDay}") {
            id
            isOrientation
            startsAt
            endsAt
            location {
              id
              name
              address {
                city
                lat
                long
                state
                street
                unit
                timezone
                zip
              }
            }
            locationless
            payLumpSum
            payRate
            position {
              id
              name
            }
            shiftBonuses {
              amount
            }
            work {
              worker { id }
              earnings
              startedAt
              completedAt
              status
              callouts {
                status
              }
            }
            numWorkersFilled
          }
          listings {
            id
            numWorkersPending
            shifts (startDate: "${startDateDay}", endDate: "${endDateDay}") {
              id
              numWorkersPending
            }
          }
        }
      }
    `,
    'assignment_calendar_view'
  )

  const result = path(['data', 'data', 'company', 'assignment'], data) || []
  const shiftsByDay = {}
  const shiftsById = {}

  result.shifts.forEach(({ work, ...shift }) => {
    const date = getShiftDate(shift)
    shiftsByDay[date] = (shiftsByDay[date] || []).concat({ id: shift.id })
    shiftsById[shift.id] = {
      ...shift,
      workByWorker: indexBy((w) => String(w?.worker?.id), work),
    }
  })

  return {
    shiftsById,
    shiftsByDay,
    workers: result.workers,
    listings: result.listings,
  }
}
