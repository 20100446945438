import React, { useState } from 'react'
import { Box, Button, Card, Field, Flex, Input, Select, Text, toast } from 'ui'
import { activateDemoMode, deactivateDemoMode, isDemoMode } from 'api/demo'
import { DemoCategory } from 'api/demo/types'

const categoryOptions = [
  {
    label: 'General',
    value: 'general',
  },
  {
    label: 'Hospitality',
    value: 'hospitality',
  },
  {
    label: 'Driving',
    value: 'driving',
  },
  {
    label: 'Warehouse',
    value: 'warehouse',
  },
]

export function DemoSettings() {
  const [category, setCategory] = useState<string>('general')
  const [takePercentage, setTakePercentage] = useState<string>('35') // default to 35%
  const [isDemo, setIsDemo] = useState(isDemoMode())
  const [isLoading, setLoading] = useState(false)

  return (
    <Flex flexDirection={'row'} width={[1, 1, 500]}>
      <Box flex={1}>
        {isDemo ? (
          <>
            <Text>Currently in demo mode</Text>
            <Button
              ml={2}
              onClick={() => {
                deactivateDemoMode()
                setIsDemo(false)
              }}
            >
              Deactivate Demo Mode
            </Button>
          </>
        ) : (
          <Card>
            <Field label="Category">
              <Select
                options={categoryOptions}
                defaultValue={categoryOptions.find((o) => o.value === category)}
                onChange={(option) =>
                  setCategory((option as { value: string }).value)
                }
              />
            </Field>
            <Field label="Take Percentage">
              <Input
                value={takePercentage}
                onChange={(e) => setTakePercentage(e.target.value)}
              />
            </Field>
            <Button
              ml={2}
              loading={isLoading}
              onClick={async () => {
                if (!Number(takePercentage)) {
                  toast.error('Take percentage must be a number')
                  return
                }
                setLoading(true)
                await activateDemoMode(category as DemoCategory, {
                  takePercentageEstimate: Number(takePercentage),
                })
                setIsDemo(true)
                window.location.href = '/'
              }}
            >
              {isLoading ? 'Generating Demo Data...' : 'Activate Demo Mode'}
            </Button>
          </Card>
        )}
      </Box>
    </Flex>
  )
}
