import { api } from '../api'

interface UpdateShiftTimesPayload {
  shift_ids: number[]
  starts_at: string
  ends_at: string
  lunch_length: number | null
}

export const updateShiftTimes = async (
  companyId: number,
  assignmentId: number,
  payload: UpdateShiftTimesPayload
) => {
  const response = await api.put(
    `/company/${companyId}/assignment/${assignmentId}/shifts`,
    payload
  )
  return response.data
}
