import React from 'react'
import { Button, ButtonProps, Tooltip } from 'ui'
import { useCompanyPositions } from 'queries/company'

export const AddPositionTrigger = (props: ButtonProps) => {
  const { data, isLoading } = useCompanyPositions()
  const trigger = (
    <Button variant={'text'} loading={isLoading} {...props}>
      + Add New Position
    </Button>
  )

  if (data?.length === 0) {
    return (
      <Tooltip
        side={'bottom'}
        sideOffset={-5}
        content={`You don't have any positions yet. Click here to add your first one.`}
        initialOpen={true}
      >
        {trigger}
      </Tooltip>
    )
  }

  return trigger
}
