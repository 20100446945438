import { AxiosResponse } from 'axios'
import { api } from './api'
import { config } from 'config'

export function graphql<T = any, R = AxiosResponse<T>>(
  q: string,
  ctx?: any,
  options = {}
) {
  let headers = {}
  if (ctx) {
    headers = { 'APP-GQL-CONTEXT': ctx }
  }

  return api.post<T, R>(
    config.graphqlHostUrl,
    {
      query: `query { ${q} }`,
    },
    { headers, ...options }
  )
}

interface GraphQLOptions {
  query: string
  variables?: Record<string, unknown>
  context?: string
}

// TODO: rename the legacy function above to graphqlDeprecated and rename this to graphql
export function graphql2({ query, variables, context }: GraphQLOptions) {
  const headers = context ? { 'APP-GQL-CONTEXT': context } : {}

  if (!query.toLowerCase().trim().startsWith('query')) {
    throw new Error('Invalid GraqhQL: Query must start with "query"')
  }

  return api.post(
    config.graphqlHostUrl,
    {
      query,
      variables,
    },
    { headers }
  )
}
