import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateShiftTimes } from 'api/assignment/updateShiftTimes'
import { assignmentShiftsKey } from './keys'

interface UseUpdateShiftTimesParams {
  companyId: number
  assignmentId: number
}

export const useUpdateShiftTimes = ({
  companyId,
  assignmentId,
}: UseUpdateShiftTimesParams) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: Parameters<typeof updateShiftTimes>[2]) =>
      updateShiftTimes(companyId, assignmentId, payload),
    onSuccess: async () => {
      // Invalidate and wait for the new data to be fetched
      await queryClient.invalidateQueries({
        queryKey: assignmentShiftsKey(assignmentId),
        refetchType: 'active', // Only refetch active queries
      })
    },
  })
}
