import React, { ReactNode, useEffect, useState } from 'react'
import {
  Flex,
  Box,
  Loading,
  Modal,
  Heading,
  Button,
  Table,
  Input,
  Message,
  useResponsiveValue,
} from 'ui'
import { useCompanyWorkers } from 'queries/company'
import { useWorkers } from './useWorkers'
import { useColumns } from './useColumns'
import { FiSearch } from 'react-icons/fi'
import { useDebounce } from 'ahooks'
import { CompanyWorker } from 'api/worker'
import { MobileCard } from './MobileCard'
import { SelectedWorker } from './SelectedWorker'

interface Props {
  title?: string
  selected?: CompanyWorker[]
  onChange?: (selected: CompanyWorker[]) => void
  open?: boolean
  loading?: boolean
  onClose?: () => void
  onGoBack?: () => void
  emptyState?: ReactNode
  selectedWorkerStatusComponent?: (
    worker: CompanyWorker
  ) => React.JSX.Element | null
}

export function WorkersSelector(props: Props) {
  const [selected, setSelected] = useState<CompanyWorker[]>(
    props.selected ?? []
  )
  const columns = useColumns(setSelected)
  const [search, setSearch] = useState('')
  const debouncedKeyword = useDebounce(search, { wait: 500 })
  const {
    data: workers,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useCompanyWorkers({
    search: debouncedKeyword,
  })
  const { unselectedWorkers } = useWorkers({
    selected,
    workers,
  })
  const showTable = useResponsiveValue([false, true])

  // external change to selected
  useEffect(() => {
    setSelected(props.selected ?? [])
  }, [props.selected])

  useEffect(() => {
    if (!props.open) {
      // reset search
      setSearch('')
    }
  }, [props.open])

  return (
    <Modal
      width={[1, 1, 800, 900]}
      loading={props.loading}
      maxWidth={'auto'}
      open={props.open}
      title={props.title || 'Add Specific Workers'}
      onClose={props.onClose}
      customCta={
        <>
          {props.onGoBack ? (
            <Box flex={1} ml={-4}>
              <Button variant={'text'} onClick={props.onGoBack}>
                Back
              </Button>
            </Box>
          ) : null}
          {selected.length > 0 ? (
            <Heading level={4} mr={3} my={0}>
              {selected.length} Selected
            </Heading>
          ) : null}
          <Button
            width={[150, 200]}
            onClick={() => {
              if (props.onChange) props.onChange(selected)
            }}
          >
            Proceed
          </Button>
        </>
      }
    >
      <Flex flexDirection={'column'} height={showTable ? '70vh' : 'auto'}>
        <Input
          rounded={true}
          placeholder={'Search'}
          icon={<FiSearch fontSize={18} />}
          onChange={(e) => setSearch(e.target.value.trim())}
        />
        <Flex flexWrap={'wrap'} my={3} overflowY="scroll" maxHeight={130}>
          {/* scroll after 2.5 rows */}
          {selected.map((worker) => {
            return (
              <SelectedWorker
                key={worker.id}
                worker={worker}
                setSelected={setSelected}
              >
                {props.selectedWorkerStatusComponent
                  ? props.selectedWorkerStatusComponent(worker)
                  : null}
              </SelectedWorker>
            )
          })}
        </Flex>
        <Box flex={1} overflow={'auto'}>
          {debouncedKeyword && debouncedKeyword.length < 3 ? (
            <Message variant={'info'}>
              Please enter at least 3 characters to search
            </Message>
          ) : isLoading || !workers ? (
            <Loading />
          ) : null}
          {!isLoading ? (
            showTable ? (
              <Table
                stickyHeader={true}
                columns={columns}
                data={unselectedWorkers}
              />
            ) : (
              unselectedWorkers.map((worker) => (
                <MobileCard
                  key={worker.id}
                  data={worker}
                  onSelect={() =>
                    setSelected((selected) => [...selected, worker])
                  }
                />
              ))
            )
          ) : null}
          {unselectedWorkers.length === 0 &&
          selected.length === 0 &&
          !isLoading &&
          props.emptyState
            ? props.emptyState
            : null}
          {hasNextPage ? (
            <Flex justifyContent={'center'} my={3}>
              <Button
                width={[1, 200]}
                variant={'secondary'}
                loading={isFetchingNextPage}
                onClick={() => fetchNextPage({})}
              >
                Load more
              </Button>
            </Flex>
          ) : null}
        </Box>
      </Flex>
    </Modal>
  )
}
