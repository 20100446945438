import React, { useState, useMemo, useEffect } from 'react'
import { Box, Button, Link, Table, Tabs, Text } from '@workwhile/ui'
import { useAssignmentShifts } from 'queries/assignment/useAssignmentShifts'
import { formatInTimeZone } from 'date-fns-tz'
import { useAssignmentDetailContextStateValue } from 'pages/AssignmentDetailPage/context'
import { ListViewControls } from './ListViewControls'
import { isAfter, isBefore, isWithinInterval } from 'date-fns'
import { AssignmentShift } from 'api/assignment/getAssignmentShifts'
import { CancelShiftFlow } from '../../CancelShiftFlow'
import { DayFilter } from './DayFilter'
import styled, { useTheme } from 'styled-components'

const StyledCheckbox = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #0ab7a3;
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: #0ab7a3;

    &::after {
      content: '';
      position: absolute;
      left: 5px;
      top: 2px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &:hover {
    background-color: ${({ checked }) => (checked ? '#0AB7A3' : '#E6FCF9')};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const ListView = () => {
  const { assignment } = useAssignmentDetailContextStateValue()
  const { data, isLoading } = useAssignmentShifts(assignment.id)
  const [selectedTab, setSelectedTab] = useState<
    'past' | 'in-progress' | 'upcoming'
  >('upcoming')
  const [selectedShifts, setSelectedShifts] = useState<string[]>([])
  const [isCancelFlowOpen, setIsCancelFlowOpen] = useState(false)
  const [selectedDays, setSelectedDays] = useState<number[]>([])
  const theme = useTheme()

  // Clear shift selections when day filters change
  useEffect(() => {
    setSelectedShifts([])
  }, [selectedDays])

  // Keep the existing useEffect for initial selection, but modify it to respect day filters
  useEffect(() => {
    if (data?.shifts && selectedDays.length === 0) {
      const upcomingShiftIds = data.shifts
        .filter((shift) => {
          const startDate = new Date(shift.startsAt)
          return isAfter(startDate, new Date())
        })
        .map((shift) => shift.id.toString())
      setSelectedShifts(upcomingShiftIds)
    }
  }, [data?.shifts, selectedDays])

  const now = new Date()

  const { pastShifts, inProgressShifts, upcomingShifts } = useMemo(() => {
    if (!data?.shifts) {
      return {
        pastShifts: [] as AssignmentShift[],
        inProgressShifts: [] as AssignmentShift[],
        upcomingShifts: [] as AssignmentShift[],
      }
    }

    const sorted = data.shifts.sort(
      (a, b) => new Date(a.startsAt).getTime() - new Date(b.startsAt).getTime()
    )

    return sorted.reduce(
      (
        acc: {
          pastShifts: AssignmentShift[]
          inProgressShifts: AssignmentShift[]
          upcomingShifts: AssignmentShift[]
        },
        shift
      ) => {
        const startDate = new Date(shift.startsAt)
        const endDate = new Date(shift.endsAt)

        if (isWithinInterval(now, { start: startDate, end: endDate })) {
          acc.inProgressShifts.push(shift)
        } else if (isBefore(endDate, now)) {
          acc.pastShifts.push(shift)
        } else if (isAfter(startDate, now)) {
          acc.upcomingShifts.push(shift)
        }

        return acc
      },
      {
        pastShifts: [],
        inProgressShifts: [],
        upcomingShifts: [],
      }
    )
  }, [data?.shifts])

  const currentShifts = useMemo(() => {
    let shifts = [] as AssignmentShift[]

    switch (selectedTab) {
      case 'past':
        shifts = pastShifts
        break
      case 'in-progress':
        shifts = inProgressShifts
        break
      case 'upcoming':
        shifts = upcomingShifts
        break
      default:
        shifts = []
    }

    // If no days are selected, show all shifts
    if (selectedDays.length === 0) {
      return shifts
    }

    // Filter shifts by selected days
    return shifts.filter((shift) => {
      const shiftDate = new Date(shift.startsAt)
      const dayOfWeek = shiftDate.getDay() // 0 = Sunday, 1 = Monday, etc.
      return selectedDays.includes(dayOfWeek)
    })
  }, [selectedTab, pastShifts, inProgressShifts, upcomingShifts, selectedDays])

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <Box>
      <Tabs
        value={selectedTab}
        onValueChange={(value) => setSelectedTab(value as typeof selectedTab)}
        tabs={[
          {
            value: 'past',
            label: `Past (${pastShifts.length})`,
          },
          {
            value: 'in-progress',
            label: `In Progress (${inProgressShifts.length})`,
          },
          {
            value: 'upcoming',
            label: `Upcoming (${upcomingShifts.length})`,
          },
        ]}
      >
        <Box mt={2}>
          <Box mb={4}>
            <DayFilter selectedDays={selectedDays} onChange={setSelectedDays} />
          </Box>

          <Table
            columns={[
              {
                header: () => (
                  <Box display="flex" alignItems="center" gap={2}>
                    <StyledCheckbox
                      type="checkbox"
                      checked={
                        Boolean(currentShifts?.length) &&
                        selectedShifts.length === currentShifts.length
                      }
                      onChange={() => {
                        const allShiftIds =
                          currentShifts?.map((shift) => shift.id.toString()) ||
                          []
                        setSelectedShifts(
                          selectedShifts.length === allShiftIds.length
                            ? []
                            : allShiftIds
                        )
                      }}
                    />
                    <Text>Select All</Text>
                  </Box>
                ),
                accessorKey: 'select',
                cell: ({ row }) => (
                  <StyledCheckbox
                    type="checkbox"
                    checked={selectedShifts.includes(
                      row.original.id.toString()
                    )}
                    onChange={() => {
                      const newSelected = selectedShifts.includes(
                        row.original.id.toString()
                      )
                        ? selectedShifts.filter(
                            (id) => id !== row.original.id.toString()
                          )
                        : [...selectedShifts, row.original.id.toString()]
                      setSelectedShifts(newSelected)
                    }}
                  />
                ),
              },
              {
                header: 'Date',
                accessorKey: 'date',
              },
              {
                header: 'Start Time',
                accessorKey: 'startTime',
              },
              {
                header: 'End Time',
                accessorKey: 'endTime',
              },
              {
                header: 'Workers',
                accessorKey: 'currentWorkers',
              },
              {
                header: () => (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gap={2}
                    width="100%"
                  >
                    <Button
                      variant="secondary"
                      size="sm"
                      disabled={
                        selectedShifts.length === 0 ||
                        selectedTab !== 'upcoming'
                      }
                      onClick={() => setIsCancelFlowOpen(true)}
                      style={{
                        color: theme.colors.errors[100],
                        borderColor: theme.colors.errors[100],
                      }}
                    >
                      Cancel Shifts
                    </Button>
                    <ListViewControls
                      selectedShifts={selectedShifts}
                      selectedTab={selectedTab}
                    />
                  </Box>
                ),
                accessorKey: 'actions',
                cell: ({ row }) => (
                  <Box display="flex" justifyContent="center">
                    <Link to={`/shift/${row.original.id}`}>View Shift</Link>
                  </Box>
                ),
              },
            ]}
            data={
              currentShifts?.map((shift) => {
                const timezone = shift.location?.address?.timezone
                return {
                  id: shift.id,
                  date: formatInTimeZone(
                    new Date(shift.startsAt),
                    timezone,
                    'EEEE, MMMM d, yyyy'
                  ),
                  startTime: formatInTimeZone(
                    new Date(shift.startsAt),
                    timezone,
                    'h:mm a (zzz)'
                  ),
                  endTime: formatInTimeZone(
                    new Date(shift.endsAt),
                    timezone,
                    'h:mm a (zzz)'
                  ),
                  currentWorkers: `${shift.numWorkersFilled} / ${shift.workersNeeded}`,
                  actions: shift.id,
                }
              }) || []
            }
          />
        </Box>
      </Tabs>

      <CancelShiftFlow
        isOpen={isCancelFlowOpen}
        close={() => setIsCancelFlowOpen(false)}
        selectedShifts={selectedShifts}
      />
    </Box>
  )
}
