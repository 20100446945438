import React from 'react'
import { Box, Button, Text, Heading, Table } from '@workwhile/ui'
import { CheckCircle, XCircle } from 'lucide-react'
import { formatInTimeZone } from 'date-fns-tz'
import { useAssignmentShifts } from 'queries/assignment/useAssignmentShifts'
import { useAssignmentDetailContextStateValue } from 'pages/AssignmentDetailPage/context'
import { getLocalTimezone } from 'lib/time'
import { getTimezoneFromLocation } from 'lib/time/getTimezoneFromLocation'

interface ResultsStepProps {
  selectedShiftIds: string[]
  success: boolean
  error?: Error | null
  onClose: () => void
}

export const ResultsStep = ({
  selectedShiftIds,
  success,
  error,
  onClose,
}: ResultsStepProps) => {
  const { assignment } = useAssignmentDetailContextStateValue()
  const { data } = useAssignmentShifts(assignment.id)

  const timezone = data?.shifts[0]?.location
    ? getTimezoneFromLocation(data.shifts[0].location)
    : getLocalTimezone()

  // Filter to only show the shifts that were actually updated
  const updatedShifts =
    data?.shifts.filter((shift) =>
      selectedShiftIds.includes(shift.id.toString())
    ) ?? []

  const columns = [
    { header: 'Date', accessorKey: 'date' },
    { header: 'Time', accessorKey: 'time' },
  ]

  const tableData = updatedShifts.map((shift) => ({
    date: formatInTimeZone(
      new Date(shift.startsAt),
      timezone,
      'EEE, MMM d, yyyy'
    ),
    time: `${formatInTimeZone(
      new Date(shift.startsAt),
      timezone,
      'h:mm a'
    )} - ${formatInTimeZone(new Date(shift.endsAt), timezone, 'h:mm a (zzz)')}`,
  }))

  return (
    <Box>
      <Heading level={2} mb={4}>
        Confirmation
      </Heading>

      {success ? (
        <>
          <Box mt={4} mb={3} display="flex" alignItems="center">
            <CheckCircle color="#0AB7A3" size={24} />
            <Text ml={3} fontSize="16px" color="primary">
              Successfully updated {updatedShifts.length} shifts
            </Text>
          </Box>

          <Table columns={columns} data={tableData} />
        </>
      ) : (
        <Box mb={4} display="flex" alignItems="center">
          <XCircle color="#DC2626" size={24} />
          <Text ml={3} fontSize="16px">
            Failed to update shifts: {error?.message}
          </Text>
        </Box>
      )}

      <Box display="flex" justifyContent="flex-end" mt={4}>
        <Button onClick={onClose}>Close</Button>
      </Box>
    </Box>
  )
}
