import React from 'react'
import * as ToggleGroup from '@radix-ui/react-toggle-group'
import { styled } from 'styled-components'
import { CalendarIcon, List } from 'lucide-react'
import { Text } from '@workwhile/ui'
type ViewType = 'calendar' | 'list'

interface ViewToggleProps {
  view: ViewType
  onViewChange: (view: ViewType) => void
}

const StyledToggleGroup = styled(ToggleGroup.Root)`
  display: inline-flex;
  background-color: white;
  border-radius: 100px;
  padding: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`

const StyledToggleItem = styled(ToggleGroup.Item)`
  all: unset;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  cursor: pointer;
  color: #c2c2c1;
  transition: all 0.2s ease;

  &[data-state='on'] {
    background-color: white;
    color: #0ab7a3;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  }

  &:hover:not([data-state='on']) {
    background-color: var(--colors-gray50);
  }
`

export const ViewToggle = ({ view, onViewChange }: ViewToggleProps) => {
  return (
    <StyledToggleGroup
      type="single"
      value={view}
      onValueChange={(value) => value && onViewChange(value as ViewType)}
    >
      <StyledToggleItem value="calendar" aria-label="Calendar view">
        <CalendarIcon />
        <Text>Calendar</Text>
      </StyledToggleItem>
      <StyledToggleItem value="list" aria-label="List view">
        <List />
        <Text>List</Text>
      </StyledToggleItem>
    </StyledToggleGroup>
  )
}
