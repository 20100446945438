import React, { useMemo } from 'react'
import {
  Box,
  Checkbox,
  Field,
  Flex,
  Heading,
  Text,
  Input,
  InputMask,
  Select,
  Button,
  MaskEnum,
} from 'ui'
import { useSupervisor } from './useSupervisor'
import { Controller } from 'react-hook-form'
import { useCompanyRoles } from 'queries/company/useCompanyRoles'
import { Selectable } from 'typings/selectable_ext'

interface Props {
  id: number
}

export function ExternalSupervisor(props: Props) {
  const { control, dataPath, register, watch, getPath, errors, remove } =
    useSupervisor(props.id)
  const sendInvite = watch('sendInvite')
  const { data: roles } = useCompanyRoles()
  const roleOptions = useMemo(() => {
    if (!roles) return []

    return roles.map((role) => ({
      value: role.id,
      label: role.description,
    }))
  }, [roles])

  return (
    <Box width={1}>
      <Flex>
        <Heading level={4} my={3} mt={2} color={'primary'}>
          Assign a Supervisor
        </Heading>
        <Box flex={1} />
        <Button onClick={remove} variant={'text'}>
          Cancel
        </Button>
      </Flex>
      <Flex flexWrap={'wrap'}>
        <Box width={[1, 1 / 2]} pr={[0, 2]}>
          <Field
            label={'Enter Assignee Name'}
            error={errors.name?.message?.toString()}
          >
            <Input
              {...register('name')}
              placeholder={'First name, Last name'}
            />
          </Field>
        </Box>
        <Box width={[1, 1 / 2]} pr={[0, 2]}>
          <Field
            label={'Phone Number*'}
            error={errors.phoneNumber?.message?.toString()}
          >
            <Controller
              control={control}
              name={`${dataPath}.phoneNumber`}
              render={({ field }) => (
                <InputMask mask={MaskEnum.PhoneNumberAlternate} {...field} />
              )}
            />
          </Field>
        </Box>
      </Flex>
      <Text mb={3} color={'lightText'}>
        *Phone number will be anonymized for privacy and will not be seen by
        workers.
      </Text>
      <Controller
        render={({ field }) => (
          <Checkbox {...field} value={field.value ?? true}>
            Invite this person to my company's WorkWhile account
          </Checkbox>
        )}
        name={getPath('sendInvite')}
      />
      {sendInvite ? (
        <Flex flexWrap={'wrap'} mt={3}>
          <Box width={[1, 1 / 2]} pr={[0, 2]}>
            <Field label={'Email'} error={errors.email?.message?.toString()}>
              <Input {...register('email')} placeholder={'Enter Email'} />
            </Field>
          </Box>
          <Box width={[1, 1 / 2]} pr={[0, 2]}>
            <Field label={'Role'} error={errors.roleId?.message?.toString()}>
              <Controller
                name={getPath('roleId')}
                render={({ field }) => (
                  <Select
                    options={roleOptions}
                    value={roleOptions.find(
                      (option) => option.value === field.value
                    )}
                    placeholder={'Select a Role'}
                    onChange={(newValue) => {
                      const data = newValue as Selectable<unknown>
                      field.onChange(data.value)
                    }}
                  />
                )}
              />
            </Field>
          </Box>
        </Flex>
      ) : null}
    </Box>
  )
}
