import { graphql } from 'api/graphql'
import { path } from 'ramda'

export interface AssignmentShift {
  id: number
  startsAt: string
  endsAt: string
  workersNeeded: number
  numWorkersFilled: number
  locationId: number
  lunchLength: number
  isOrientation: boolean
  location: {
    address: {
      city: string
      state: string
      timezone: string
    }
    name: string
  }
  position: {
    name: string
  }
}

interface AssignmentShiftsResponse {
  name: string
  position: {
    name: string
  }
  shifts: AssignmentShift[]
}

type GetAssignmentShiftsResponseDTO = {
  data: {
    company: {
      assignment: AssignmentShiftsResponse
    }
  }
}

export const getAssignmentShifts = async (
  assignmentId: number
): Promise<AssignmentShiftsResponse> => {
  const data = await graphql<GetAssignmentShiftsResponseDTO>(
    `
      company {
        assignment (assignmentId: ${assignmentId}) {
          name
          position {
            name
          }
          shifts {
            id
            startsAt
            endsAt
            workersNeeded
            numWorkersFilled
            isOrientation
            location {
              address {
                city
                state
                timezone
              }
              id
              name
            }
            position {
              name
            }
          }
        }
      }
    `,
    'assignment_shifts'
  )

  return path(['data', 'data', 'company', 'assignment'], data)
}
