import React from 'react'
import { Box, Button, Flex, Table, Text, Message, Heading } from '@workwhile/ui'
import { useAssignmentShifts } from 'queries/assignment/useAssignmentShifts'
import { formatInTimeZone } from 'date-fns-tz'
import { useAssignmentDetailContextStateValue } from 'pages/AssignmentDetailPage/context'

interface ReviewStepProps {
  selectedShifts: string[]
  onConfirm: () => void
  onCancel: () => void
  isLoading?: boolean
  error?: Error | null
}

export const ReviewStep = ({
  selectedShifts,
  onConfirm,
  onCancel,
  isLoading,
  error,
}: ReviewStepProps) => {
  const { assignment } = useAssignmentDetailContextStateValue()
  const { data } = useAssignmentShifts(assignment.id)

  // Get timezone from first shift's location
  const firstShift = data?.shifts.find((shift) =>
    selectedShifts.includes(shift.id.toString())
  )
  const timezone =
    firstShift?.location?.address?.timezone ?? 'timezone unavailable'

  const columns = [
    { header: 'Date', accessorKey: 'date' },
    { header: 'Time', accessorKey: 'time' },
  ]

  const selectedShiftData =
    data?.shifts.filter((shift) =>
      selectedShifts.includes(shift.id.toString())
    ) || []

  return (
    <Box>
      <Heading level={2} mb={4}>
        Are you sure you want to cancel these shifts?
      </Heading>

      <Message variant="warning" mb={4}>
        This action cannot be undone and the selected shifts will be cancelled
        immediately.
        <br />
        Any scheduled workers will be notified of this cancellation.
      </Message>

      {error ? (
        <Message variant="error" mb={4}>
          Failed to cancel shifts: {error.message}
        </Message>
      ) : null}

      <Box mb={4}>
        <Text fontWeight="bold" mb={2}>
          Selected Shifts ({selectedShiftData.length})
        </Text>
        <Table
          columns={columns}
          data={selectedShiftData.map((shift) => ({
            date: formatInTimeZone(
              new Date(shift.startsAt),
              timezone,
              'EEE, MMM d, yyyy'
            ),
            time: `${formatInTimeZone(
              new Date(shift.startsAt),
              timezone,
              'h:mm a'
            )} - ${formatInTimeZone(
              new Date(shift.endsAt),
              timezone,
              'h:mm a (zzz)'
            )}`,
          }))}
        />
      </Box>

      <Flex justifyContent="flex-end" gap={2} mt={4}>
        <Button variant="secondary" onClick={onCancel} disabled={isLoading}>
          No, Go Back
        </Button>
        <Button
          variant="primary"
          onClick={onConfirm}
          disabled={isLoading}
          loading={isLoading}
        >
          Yes, Confirm
        </Button>
      </Flex>
    </Box>
  )
}
