import React, { forwardRef } from 'react'
import { Avatar, Flex, useAppTheme } from '@workwhile/ui'
import styled from 'styled-components'
import { BaseWorkerShiftCellProps } from './BaseWorkerShiftCell'

const StyledButton = styled('button').withConfig({
  shouldForwardProp: (prop) => !['borderStyle'].includes(prop),
})<Pick<BaseWorkerShiftCellProps, 'borderStyle'>>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid ${({ theme }) => theme.colors.neutrals[100]};
  color: ${({ theme }) => theme.colors.neutrals[400]};
  border-style: ${({ borderStyle }) => borderStyle};
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii.small};
  cursor: pointer;
  width: 100%;
  background: transparent;
  &:focus-visible {
    box-shadow: 0px 0px 7px 1px ${({ theme }) => theme.colors.neutrals[200]};
  }
`

type BaseWorkerCardCellProps = {
  avatar?: {
    photoUrl?: string
    name?: string
  }
  title: React.ReactNode
  onClick?: () => void
}

export const BaseWorkerCardCell = forwardRef<
  HTMLButtonElement,
  BaseWorkerCardCellProps
>(({ avatar = {}, title, onClick }, ref) => {
  const theme = useAppTheme()

  return (
    <StyledButton onClick={onClick} ref={ref}>
      <Flex
        as="span"
        flexDirection="row"
        alignItems="center"
        borderStyle="solid"
        borderWidth="2px"
        borderColor={theme.colors.neutrals[100]}
        borderRadius={theme.radii.small}
        size="100%"
        p={2}
        style={{
          gap: theme.space[2],
        }}
      >
        <Avatar
          size="small"
          user={{
            photoUrl: avatar?.photoUrl,
            name: avatar?.name,
          }}
          badge={null}
        />
        {title}
      </Flex>
    </StyledButton>
  )
})

BaseWorkerCardCell.displayName = 'BaseWorkerCardCell'
