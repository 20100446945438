import React from 'react'
import { Button, ButtonProps, Tooltip } from 'ui'
import { useCompanyLocations } from 'queries/company'

export function AddLocationTrigger(props: ButtonProps) {
  const { data, isLoading } = useCompanyLocations()
  const trigger = (
    <Button variant={'text'} loading={isLoading} {...props}>
      + Add New Location
    </Button>
  )

  if (data?.length === 0) {
    return (
      <Tooltip
        side={'bottom'}
        sideOffset={-5}
        content={`You don't have any locations yet. Click here to add your first one.`}
        initialOpen={true}
      >
        {trigger}
      </Tooltip>
    )
  }

  return trigger
}
