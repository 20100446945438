import { ApiRequest, DemoData, GraphqlRequest } from 'api/demo/types'
import { pathOr } from 'ramda'

const DEMO_STORAGE_KEY = 'demoData'

export function isGraphqlRequest(request: ApiRequest) {
  return request.url?.split('/').pop() === 'graphql'
}

export function matchGraphqlRequest(
  request: ApiRequest,
  matchStr: string | RegExp
) {
  if (!isGraphqlRequest(request)) {
    return false
  }
  const query: string = pathOr('', ['data', 'query'], request).replace(
    /\s/g,
    ''
  )
  return Boolean(query && !!query.match(matchStr))
}

export function matchRestRequest(
  request: ApiRequest,
  matchStr: string | RegExp,
  method = 'GET'
) {
  if (isGraphqlRequest(request)) {
    return false
  }
  const url = request.url
  const reqMethod = request.method || 'GET'
  return Boolean(
    url &&
      reqMethod.toLowerCase() === method.toLowerCase() &&
      !!url.match(matchStr)
  )
}

export function getGraphqlRequest(request: ApiRequest) {
  const query: string = pathOr('', ['data', 'query'], request).replace(
    /\s/g,
    ' '
  )
  const match = /\((?<arg>.*?)\)/g.exec(query)
  const variables: Record<string, unknown> = {}

  if (match && match.groups?.arg) {
    match.groups.arg.split(',').forEach((arg) => {
      const [key, value] = arg.split(/:(.*)/s)
      if (key && value) {
        variables[key.trim()] = JSON.parse(value.trim())
      }
    })
  }
  return {
    request,
    query,
    variables,
  } as GraphqlRequest
}

export function clearDemoData() {
  sessionStorage.removeItem(DEMO_STORAGE_KEY)
}

const emptyDemoData = {
  category: 'general',
  shifts: [],
  workers: [],
  rosters: [],
  locations: [],
  positions: [],
  supervisors: [],
  companyMembers: [],
  companyOverrides: {
    takePercentageEstimate: 35,
  },
}

export function getDemoData(): DemoData {
  const demoDataStr = sessionStorage.getItem(DEMO_STORAGE_KEY)
  if (demoDataStr) {
    return JSON.parse(demoDataStr)
  }

  return emptyDemoData as DemoData
}

export function setDemoData(demoData: Record<string, unknown>) {
  sessionStorage.setItem(DEMO_STORAGE_KEY, JSON.stringify(demoData))
}

export function isDemoMode() {
  return !!sessionStorage.getItem(DEMO_STORAGE_KEY)
}
