import { api } from '../api'

interface CancelShiftsPayload {
  shift_ids: number[]
}

export const cancelShifts = async (
  companyId: number,
  assignmentId: number,
  payload: CancelShiftsPayload
) => {
  const response = await api.put(
    `/company/${companyId}/assignment/${assignmentId}/shifts/cancel`,
    payload
  )
  return response.data
}
