import React from 'react'
import { Box } from '@workwhile/ui'
import {
  CalendarView,
  CalendarViewProvider,
  AssignmentWorkflowsProvider,
} from './Calendar'
import { ListView } from './ListView/ListView'
import { ViewToggle } from './ViewToggle/ViewToggle'
import { EditShiftFlow } from '../EditShiftFlow'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGate } from 'statsig-react'
import { FeatureGates } from 'lib/statsig/feature_gates'

export const ScheduleView = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const view = location.pathname.endsWith('/list') ? 'list' : 'calendar'
  const { value: shouldEnableLTActions } = useGate(
    FeatureGates.EnableLTAActions
  )

  const handleViewChange = (newView: 'calendar' | 'list') => {
    const basePath = location.pathname.split('/').slice(0, -1).join('/')
    navigate(`${basePath}/${newView}`)
  }

  return (
    <Box>
      {shouldEnableLTActions ? (
        <Box display="flex" justifyContent="flex-end" marginBottom="16px">
          <ViewToggle view={view} onViewChange={handleViewChange} />
        </Box>
      ) : null}

      <AssignmentWorkflowsProvider>
        {view === 'calendar' ? (
          <CalendarViewProvider>
            <CalendarView />
          </CalendarViewProvider>
        ) : (
          <>
            <ListView />
            <EditShiftFlow />
          </>
        )}
      </AssignmentWorkflowsProvider>
    </Box>
  )
}
