import { graphql } from 'api/graphql'
import { getDemoData, matchGraphqlRequest } from '../utils'
import { CompanyInfo, FALL_BACK_TAKE_PERCENTAGE } from 'api/company/companyInfo'
import { path } from 'ramda'

export const mockGetCompanyInfo = {
  shouldMock: (request) =>
    matchGraphqlRequest(request, /GetCurrentCompanyInfo/),
  getResponse: async () => {
    const realCompanyInfo = await getCompanyInfo()
    const demoData = getDemoData()
    return {
      data: {
        company: {
          ...realCompanyInfo,
          takePercentageEstimate:
            demoData.companyOverrides?.takePercentageEstimate ??
            realCompanyInfo?.takePercentageEstimate ??
            FALL_BACK_TAKE_PERCENTAGE,
        },
      },
    }
  },
}

async function getCompanyInfo() {
  const data = await graphql(
    `
    company {
      name, id, logoUrl, takePercentageEstimate, chargeUpfront
      companyGroup { id, companies { id, name } }, w2
    }
    `,
    'current_company'
  )
  const company = path(['data', 'data', 'company'], data) as CompanyInfo

  if (!company) {
    return null
  }

  return {
    ...company,
    id: Number(company.id),
    takePercentageEstimate: company.takePercentageEstimate
      ? Number(company.takePercentageEstimate)
      : FALL_BACK_TAKE_PERCENTAGE,
  }
}
