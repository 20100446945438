import React from 'react'
import { Box, Text } from '@workwhile/ui'
import styled from 'styled-components'

const DayCircle = styled.button<{ selected: boolean }>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #0ab7a3;
  background-color: ${({ selected }) => (selected ? '#0AB7A3' : 'transparent')};
  color: ${({ selected }) => (selected ? '#FFFFFF' : '#0AB7A3')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;

  &:hover {
    background-color: ${({ selected }) => (selected ? '#0AB7A3' : '#E6FCF9')};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const DAYS = [
  { label: 'M', value: 1 },
  { label: 'T', value: 2 },
  { label: 'W', value: 3 },
  { label: 'T', value: 4 },
  { label: 'F', value: 5 },
  { label: 'S', value: 6 },
  { label: 'S', value: 0 },
]

interface DayFilterProps {
  selectedDays: number[]
  onChange: (days: number[]) => void
}

export const DayFilter = ({ selectedDays, onChange }: DayFilterProps) => {
  const toggleDay = (day: number) => {
    const newSelectedDays = selectedDays.includes(day)
      ? selectedDays.filter((d) => d !== day)
      : [...selectedDays, day]
    onChange(newSelectedDays)
  }

  return (
    <Box>
      <Text fontWeight="bold" mb={2}>
        Filter by Day
      </Text>
      <Box display="flex" gap={2}>
        {DAYS.map(({ label, value }) => (
          <DayCircle
            key={value}
            selected={selectedDays.includes(value)}
            onClick={() => toggleDay(value)}
          >
            {label}
          </DayCircle>
        ))}
      </Box>
    </Box>
  )
}
